if (process.env.NODE_ENV === `production`) {
    exports.hostname = 'sotuw.com'
    exports.baseUrl = `https://sotuw.com`
    exports.cdnUrl = `https://sotuw.com`
    exports.mongoUrl = `mongodb://admin:liangliang@sotuwdatabase/sotuw`//数据库地址
    exports.toolApiUrl = 'https://tool-api.sotuw.com'  //工具
    // exports.redisUrl = 'sotuwredisdata'
    // exports.redisAuth = 'liangliang'
    exports.redisConfig = {
        password: 'liangliang',
        port: 6379,
        host: '172.17.0.3'
    }
} else {
    exports.hostname = 'sotuw.com'
    exports.baseUrl = `http://127.0.0.1:8800`
    exports.cdnUrl = `https://sotuw.com`
    // exports.mongoUrl = `mongodb://admin:liangliang@119.23.242.118:29701/sotuw`
    exports.mongoUrl = `mongodb://admin:liangliang@192.168.2.94:27017/sotu`
    exports.toolApiUrl = 'http://127.0.0.1:2188'  //工具
    // exports.redisUrl = '192.168.2.94'
    // exports.redisAuth = 'liangliang'
    exports.redisConfig = {
        password: 'liangliang',
        port: 6379,
        host: '192.168.2.94'
    }
}

exports.background = "linear-gradient(45deg,#5a3694 0%,#13bdce 33%,#0094d9 66%,#6fc7b5 100%)" //首页背景
exports.iconImgUrl = `${this.cdnUrl}/file/icon/` //首页图标
exports.iconImgMinUrl = `${this.iconImgUrl}min/` //首页图标缩略
exports.bgImgUrl = `${this.cdnUrl}/file/bg/min/` //背景下载
exports.gradualImgUrl = `${this.baseUrl}/file/gradual/`  //渐变下载
exports.downloadUrl = `${this.cdnUrl}/`  //字体下载

exports.qqConfig = {
    appID:`101909243`,
    appKey:`3396f88543e9d0718f2824a6283f6a90`,
    redirectUri:`${this.baseUrl}/api/v1/user/qqLogin`
}

exports.wxLogin = {
    AppID:`wx07c998b6ec3b8d7d`,
    Secret:`dbd1419a8d7f482609eea2e3aad3454b`
}

exports.baiduApi = {
    APP_ID:`22398423`,
    API_KEY:`7Fj6m5v6fDOhjroI38BgzLla`,
    SECRET_KEY:`Kso68rN6Y7jRsvW6OQBzqWGI3mRLoNf0`
}
import { baseUrl } from "../server/config"

export const title = '搜图网'
export const domain = 'sotuw.com'
export const colorPrimary = '#f73859'
export const brief = '-一款专业的聚合图像搜索引擎'
export const banner = '为您提供专业快速的图像搜索'
export const keywords = '搜图网，搜图，图像搜索，在线搜图，免费素材，设计网站，设计搜图，淘宝搜图，智能搜图，设计工具，以图搜图，设计配色，智能配色'
export const description = '搜图网拥有国内外免版权在线聚合图片搜索引擎,其中还包括以图搜图、传图取色、字体生成、智能色板、设计工具等功能。目前提供四大图库的聚合图片搜索，拥有千万张高质量免版权图片。不仅提供图像搜索还拥有超强大的个性化定制等功能。搜图就上搜图网!'
export const baiduTongji = 'e36835fe90cddbf1d8e3ce21144ac583' //百度统计
export const googleTongji = '' //谷歌统计
export const adsense = 'ca-pub-5589121902188546' //谷歌广告

export const pattern = true //true id false pinyin


export const QQ = '212181324'
export const WX = 'li474782977'
export const MAIL = '474782977@qq.com'

export const nav = [
    { title: '首页', href: '/' },
    { title: '字库', href: '/font' },
    { title: '配色', href: '/colors/plate' },
    // { title: '教程', href: '/curriculum' },
    { title: '工具', href: 'https://www.67tool.com', isLink: true },
    { title: '视频', href: 'https://www.aewz.com', isLink: true }
]

export const menu = [
  { title: '设为首页', href: '/help/course/',isLink: true},
  // { title: '搜图快搜', href: 'http://so.91sotu.com/', isLink: true },
  // { title: '国际版本', href: 'http://www.designweblink.com/', isLink: true },
  { title: '反馈留言', href: '/feedback' },
  { title: '最新收录', href: '/map' },
  { title: '更新日志', href: '/help/log/', isLink: true },
  { title: '关于搜图', href: '/about' },
]

export const about = {
    title: `关于我们-${title}`,
    keywords: `关于${title}`,
    description: `这里有关于${title}的一切，快进来看看吧！`
}

export const version = {
    date:'20210805',
    html:(
        <div>
          <h3>
            版本更新记录
            <p>
              版本 6.0.0
              <br />2021-08-05
            </p>
          </h3>
          <p>
            <br />1、优化首页渲染速度
            <br />2、优化自定义网址体验
            <br />2-1、自定义网址支持图标、标签
            <br />2-2、自定义网址支持自动填写
            <br />3、优化网站跳转速度
            <br />4、优化站内搜索体验
            <br />5、配色模块全新改版
            <br />5-1、支持中文色彩名、HEX值组合搜索
            <br />5-2、支持HEX、RGB、HSL、CMYKA、RGB、中文名称、中文传统名称、英文名称
            <br />5-3、传图取色支持色板调整以及下载
            <br />6、优化自定义壁纸体验
            <br />7、加强网站安全性
            <br />8、升级留言板
            <br />官方QQ一群(满)：
            <a
              href="https://qm.qq.com/cgi-bin/qm/qr?k=0j9P-7xr-mAs_0ghx5VpE51GW-04_O1V&jump_from=webapi"
              target="_blank">965129980</a>
            <br />官方QQ二群：
            <a
              href="https://qm.qq.com/cgi-bin/qm/qr?k=YJ5JCr9H4CNDgK1vPQ04P8MyNbF8j-om&jump_from=webapi"
              target="_blank"
            >734561308</a
            >
            (超热闹！设计师们快到碗里来！)
            <br />
            <a href={`${baseUrl}/help/log/`} target="_blank">版本记录</a>
          </p>
        </div >
      )
}
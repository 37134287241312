import { Media, Switch, User } from "../@types/reducer"

export const actionTypes = {
  FAILURE: 'FAILURE',
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
  RESET: 'RESET',
  LOAD_DATA: 'LOAD_DATA',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  START_CLOCK: 'START_CLOCK',
  TICK_CLOCK: 'TICK_CLOCK',
  HYDRATE: 'HYDRATE',
  RESPONSIVE:'RESPONSIVE',
  IS_WEBP:'IS_WEBP',
  IS_SWITCH:'IS_SWITCH',
  IS_BANNER:'IS_BANNER',
  IS_SEARCH:"IS_SEARCH",
  IS_MODAL: 'IS_MODAL',
  SET_USER_INFO:'SET_USER_INFO',
  DEL_USER_INFO:'DEL_USER_INFO',
}

export function failure(error: any) {
  return {
    type: actionTypes.FAILURE,
    error,
  }
}

export function increment() {
  return { type: actionTypes.INCREMENT }
}

export function responsive(data:Media) {
  return { type: actionTypes.RESPONSIVE, data}
}

export function isSwitch(data:Switch) {
  return { type: actionTypes.IS_SWITCH, data}
}

export function isBanner(data: boolean) {
  return { type: actionTypes.IS_BANNER, data }
}

export function setIsModal(data: boolean) {
  return { type: actionTypes.IS_MODAL, data }
}

export function isSearch(data:boolean) {
  return { type: actionTypes.IS_SEARCH, data}
}

export function isWebp(data:boolean) {
  return { type: actionTypes.IS_WEBP, data}
}

export function setUserInfo(data:User) {
  return { type: actionTypes.SET_USER_INFO, data}
}

export function delUserInfo() {
  return { type: actionTypes.DEL_USER_INFO}
}

export function decrement() {
  return { type: actionTypes.DECREMENT }
}

export function reset() {
  return { type: actionTypes.RESET }
}

export function loadData() {
  return { type: actionTypes.LOAD_DATA }
}

export function loadDataSuccess(data: any) {
  return {
    type: actionTypes.LOAD_DATA_SUCCESS,
    data,
  }
}

export function startClock() {
  return { type: actionTypes.START_CLOCK }
}

export function tickClock(isServer: boolean) {
  return {
    type: actionTypes.TICK_CLOCK,
    light: !isServer,
    ts: Date.now(),
  }
}

import type { AppContext, AppProps /*, AppContext */ } from 'next/app'
import { useDispatch } from 'react-redux'
import '../styles/global.scss'
// import { useStore } from '../redux/store'
// import 'antd/dist/antd.less'
// import '../styles/global.less'
// import '../styles/lessvars.less'
import 'antd/dist/antd.css'
// import '../styles/vars.css'
import useResponsive from '../utils/responsive'
import { useEffect } from 'react'
import { Switch } from '../@types/reducer'
import router, { Router } from 'next/router';
import { isWebp, isSwitch, responsive, setUserInfo } from '../redux/actions';
// import '../utils/helpers/find.polyfill';
import zhCN from 'antd/lib/locale/zh_CN';
// import 'moment/locale/zh-cn'
import { ConfigProvider } from 'antd';
import { wrapper } from '../redux/store'
import App from 'next/app'
import { pageview } from '../utils/gtag'

export function reportWebVitals(metric: any) {
  console.log(metric)
}

function MyApp({ Component, pageProps }: AppProps) {
  const mediaData = useResponsive()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(responsive(mediaData))
  }, [mediaData])

  useEffect(() => {
    const getSwitch = localStorage.getItem('isSwitch')
    if (getSwitch) {
      const switchData: Switch = JSON.parse(getSwitch)
      dispatch(isSwitch(switchData))
    }

    let iswebp =
      0 ==
      document
        .createElement("canvas")
        .toDataURL("image/webp")
        .indexOf("data:image/webp");
    if (iswebp) dispatch(isWebp(iswebp))
  }, [])

  if (process.env.NODE_ENV === `production`) {
    // Router.events.on('routeChangeComplete', (url) => {

    // })
    useEffect(() => { //谷歌统计
      const handleRouteChange = (url: string) => {
        pageview(url);
        try {
          window._hmt.push(['_trackPageview', url]);
        } catch (e) {

        }
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }, [router.events]);
  }
  return (
    // <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <Component {...pageProps} />
    </ConfigProvider>
    // </Provider>
  )
}
MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext
  const req: any = ctx.req
  const appProps = await App.getInitialProps(appContext);
  if (req && req.session && req.session.user) {
    ctx.store.dispatch(setUserInfo(req.session.user))
  }
  return { ...appProps }
}

export default wrapper.withRedux(MyApp)

import { useState, useLayoutEffect, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

function useResponsive() {
  const [isClient, setIsClient] = useState(false);
  const useCustomLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
  // xs	屏幕 < 576px 
  // sm	屏幕 ≥ 576px 
  // md	屏幕 ≥ 768px 
  // lg	屏幕 ≥ 992px 
  // xl	屏幕 ≥ 1200px 
  // xxl	屏幕 ≥ 1600px 
  const isXs = useMediaQuery({
    minWidth: 0,
    maxWidth: 479,
  });
  const isSm = useMediaQuery({
    maxWidth: 767,
    minWidth: 480,
  });
  
  const isMd = useMediaQuery({
    maxWidth: 1299,
    minWidth: 768,
  });
  
  const isXxl = useMediaQuery({
    minWidth: 1300
  });

  useCustomLayoutEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isXxl: isClient ? isXxl : true,
    isMd: isClient ? isMd : false,
    isSm: isClient ? isSm : false,
    isXs: isClient ? isXs : false,
  };
}

export default useResponsive;
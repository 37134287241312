import { actionTypes } from './actions'
import { HYDRATE } from 'next-redux-wrapper'
import { State, Switch, User } from '../@types/reducer'
import { cdnUrl, baseUrl, wxLogin } from '../server/config'
interface Action extends State {
  payload: any
  error: any
  data: any
  ts: any
  type: string,
  switch: Switch,
  webp: boolean,
  userInfo: User
}

const initialState = {
  count: 0,
  error: false,
  lastUpdate: 0,
  light: false,
  placeholderData: null,
  isWebp: false,
  cdnUrl,
  baseUrl,
  wxConfig: wxLogin,
  responsive: {
    isXxl: true,
    isMd: false,
    isSm: false,
    isXs: false,
  },
  isSwitch: {
    icon: true,
    tag: true,
    brief: true,
    data: true
  },
  isBanner: false,
  isSearch: false,
  isModal: false,
  userInfo: {
    number: '',
    name:'',
    id: ''
  },
}

function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload }
    }
    case actionTypes.RESPONSIVE:
      console.log(action.data)
      return {
        ...state,
        ...{ responsive: action.data }
      }
    case actionTypes.IS_SWITCH:
      return {
        ...state,
        ...{ isSwitch: action.data }
      }
    case actionTypes.IS_WEBP:
      return {
        ...state,
        ...{ isWebp: action.data }
      }
    case actionTypes.IS_MODAL:
      return {
        ...state,
        ...{ isModal: action.data }
      }
    case actionTypes.IS_BANNER:
      return {
        ...state,
        ...{ isBanner: action.data }
      }
    case actionTypes.IS_SEARCH:
      return {
        ...state,
        ...{ isSearch: action.data }
      }
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        ...{ userInfo: action.data }
      }
    case actionTypes.DEL_USER_INFO:
      return {
        ...state,
        ...{ userInfo: initialState.userInfo }
      }
    case actionTypes.FAILURE:
      return {
        ...state,
        ...{ error: action.error },
      }

    case actionTypes.INCREMENT:
      return {
        ...state,
        ...{ count: state.count + 1 },
      }

    case actionTypes.DECREMENT:
      return {
        ...state,
        ...{ count: state.count - 1 },
      }

    case actionTypes.RESET:
      return {
        ...state,
        ...{ count: initialState.count },
      }

    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...{ placeholderData: action.data },
      }

    case actionTypes.TICK_CLOCK:
      return {
        ...state,
        ...{ lastUpdate: action.ts, light: !!action.light },
      }

    default:
      return state
  }
}

export default reducer
